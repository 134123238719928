/*
* Main Navigation of the website in the header
*/

.c-mainnav {
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
    }
    
    li.is-active {
        a {
            text-decoration: underline;
            text-decoration-thickness: 15%;
            text-underline-offset: rem(4);
        }
    }
    
    a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem(18);
        font-weight: $global-weight-bold;
        padding: $base-link-padding;
        letter-spacing: rem(1);
        
        &:hover {
            color: cssvar(color-accent);
        }
    }
    
    // medium down screen exceptions
    @include breakpoint(large, down) {
        margin: rem(10 0 0 -7);
        
        ul {
            display: inline-block;
        }
    
        li {
            margin: rem(5 0);
        }
    
        a {
            display: block;
        }
    }
}

