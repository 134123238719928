/*
* Slider component
*/

.c-slider {
    max-width: rem(1920);
    position: relative;
    margin: auto;
    --slider-item-flex: 1 0 100%;

    &.has-no-prev.has-no-next {
        
        .c-slider__track {
            cursor: default;
        }
        
        .c-slider__control {
            display: none;
        }
    }
    
    &__track {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        height: auto;
        
        // to show visually that the user can scroll with mouse
        cursor: all-scroll;
        
        // Slider options
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        scroll-snap-points-x: repeat(100%);
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scroll-padding: 0;
        
        // hide scrollbar
        scrollbar-width: none; // Firefox
    
        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }
        
        &.has-mousedown {
            scroll-snap-type: unset;
            scroll-behavior: unset;
        }
    }
    
    &__item {
        flex: var(--slider-item-flex);
        scroll-snap-align: start;
    }
    
    &__control {
        z-index: 14;
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        display: none;
        padding: rem(8);
        margin: 0;
        background: none;
        border: none;
        cursor: pointer;
        line-height: normal;

        @media (hover: none) {
            display: none !important;
        }

        @include breakpoint(large) {
            display: block;
        }

        svg {
            width: rem(11);
            height: rem(19);
            fill: cssvar(color-primary);
        }

        &--prev {
            left: rem(-40);
        }

        &--next {
            right: rem(-40);
        }

        &.is-disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }
    
    &__dots {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin-bottom: rem(20);
        width: 100%;
        
        li {
            flex: 0 0 auto;
            margin-right: rem(10);
    
            button {
                display: block;
                width: rem(15);
                height: rem(15);
                margin: 0;
                padding: 0;
                background-color: cssvar(color-primary);
                background-clip: content-box;
                border: 0.25rem solid transparent;
                border-radius: 50%;
                font-size: 0;
                transition: background-color 0.1s;
                border: rem(1) solid cssvar(color-white);
                cursor: pointer;
    
                // TODO: do this only for non-click devices (e.g. keyboard navigation)
                &:focus-visible {
                    border-color: cssvar(color-primary);
                }
            }
    
            &.is-active button,
            button:hover {
                background-color: cssvar(color-accent);
            }
    
            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    &__thumbs {
        display: none;
        list-style-type: none;
        padding: 0;

        @include breakpoint(medium) {
            display: flex;
        }
        
        li {
            flex: 1 1 auto;
            margin-right: rem(5);
            cursor: pointer;
            border: 3px solid transparent;

            &:last-child {
                margin-right: 0;
            }

            @media (pointer: fine) {

                &:focus:not(:focus-visible):hover,
                &:hover,
                &:focus-visible {

                    img {
                        opacity: .8;
                    }
                }
            }

            &.is-active {
                border-color: cssvar(color-accent);
            }
        }

        figure {
            margin: 0;
            height: 100%;
            width: 100%;
        }

        img {
            object-fit: cover;
            max-height: rem(100);
            transition: opacity .3s ease-in-out;
        }

    }
    
    &__image {
        width: 100%;
        height: auto;
        max-height: rem(624);
        object-fit: contain;
    }
    
    &--hero {
        margin: auto calc(cssvar(grid-gutter) / 2 * -1) auto calc(cssvar(grid-gutter) / 2 * -1);
        
        @include breakpoint(large) {
            margin: auto;
        }
    }
    
    &--thumbs {
       
        figure {
            position: relative;
        }
        // make slide be full width on small screens
        @include breakpoint(medium, down) {
            margin-left: calc(cssvar(grid-gutter) * -2);
            margin-right: calc(cssvar(grid-gutter) * -2);
        }
    }

}