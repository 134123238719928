.c-link {
    z-index: 1;
    display: flex;
    align-self: end;
    text-decoration: none;
    color: currentColor;
    
    &:hover {
        span {
            transition: color 0.3s ease-in-out;
            color: cssvar(color-accent);
        }

        .c-link__icon {
            fill: cssvar(color-accent);
        }
    }

    &__icon {
        width: var(--icon-width, rem(20));
        height: var(--icon-height, rem(20));
        fill: currentColor;
    }

    span {
        margin-left: rem(10);
    }
}

