/**
* TeamBlock component
*/

.c-teamblock {
    list-style-type: none;
    margin: $global-margin auto;
    padding: 0;

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    figure {
        cursor: pointer;
    }
}
