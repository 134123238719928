/*
* Button Toolbar component is found in the user dashboard -> waiting list and reservation sections
*/

.c-button-toolbar {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    li {
        flex-basis: auto;
        margin-left: rem(5);
    }
    
    button {
        margin-bottom: 0;
    }
    
    &--dashboard {
        justify-content: center;
        margin-top: $global-margin;
        @include breakpoint(large) {
            margin-top: 0;
            justify-content: flex-end;
        }
    }
}