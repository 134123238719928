.c-callout {
    padding: $global-padding;
    margin: $global-margin 0;
    border: 1px solid var(--callout-color);
    color: var(--callout-color);
    background-color: var(--callout-bg-color, transparent);

    h3, a {
        color: var(--callout-color);
    }

    &--course-hint {
        display: flex;
        font-weight: 300;
        border: 3px dotted #{cssvar(color-alert)};
        color: cssvar(color-darkergray);

        &-course-hint-exlamation-mark {
            color: cssvar(color-alert);
            font-weight: 700;
            font-size: rem(60);
        }

        p {
            font-style: italic;
        }
    }

    &--success {
        overflow-x: auto;
        --callout-color: #{cssvar(color-success)};
    }

    &--error {
        overflow-x: auto;
        --callout-color: #{cssvar(color-error)};
    }

    &--info {
        overflow-x: auto;
        --callout-color: #{cssvar(color-info)};
    }

    &--warning {
        overflow-x: auto;
        --callout-color: #{cssvar(color-warning)};
    }
    
    &--error-bg {
        --callout-bg-color : #{cssvar(color-error)};
        --callout-color: #{cssvar(color-white)};
        border: none;
    }

}
