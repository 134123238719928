/*
* Generic Table styling
* - Specific tables down below
*/

table {
    text-align: left;

    th{
        font-weight: 400;
        padding: rem(10);
    }

    td {
        p {
            margin-bottom: calc($global-margin / 2);
        }
    }
}

.c-price-table {
    width: 100%;
    border: rem(1) solid cssvar(color-lightgray);
    
    th {
        border-bottom: rem(1) solid cssvar(color-lightgray);
    }

    td {
        padding: rem(5 10);
    }
    
    &__sum-divider {
        border-top: rem(1) solid cssvar(color-lightgray);
    }
}

.c-booking-info-table {
    th {
        vertical-align: top;
        padding-right: $global-margin*3;
    }
}

.c-courseinfo-table {
    width: 100%;
    
    th {
        vertical-align: top;
        min-width: rem(130);
        padding: 0 $global-margin 0 0;
    }
    
    tr {
        display: block;
        padding: calc($global-margin / 2) 0;
        border-bottom: 1px solid rgba(var(--bordercolor), 0.5);

        &:last-child {
            border: none;
        }
    }
}