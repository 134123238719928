.c-breadcrumb {
    
    &__list {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin-bottom: $global-margin*2;
        font-size: rem(11);
    }
    
    li:not(:last-child)::after{
        position: relative;
        margin: 0 0.75rem;
        content: "/";
        color: cssvar(color-lightgray); //#cacaca;
    }
    
    a {
        text-decoration: none;
    }

}