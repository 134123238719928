// TODO refactor out what is not needed from ABY

[data-tooltip-hover] {
    cursor: pointer;
}
.c-tooltip {
    position: relative;
    height: rem(20); // Why a fix height?

    &__content {
        display: none;
        padding: rem(10 15);
        border-radius: $global-radius;
        background-color: cssvar(color-primary);
        color: cssvar(color-white);
        z-index: 2;
        min-width: var(--tooltip-minwidth, rem(250));
        text-align: center;
        
        &[data-show] {
            display: block;
            animation: .1s fadeIn linear;
        }
    }

    &__arrow {
        visibility: hidden;

        &::before {
            visibility: visible;
            content: '';
            transform: rotate(45deg);
        }
    }

    &__arrow,
    &__arrow::before {
        position: absolute;
        width: rem(8);
        height: rem(8);
        background: inherit;
        left: calc(50% - rem(4));
    }

    //tooltip offset
    &__content[data-popper-placement^='top']>&__arrow {
        bottom: -4px;
    }

    &__content[data-popper-placement^='bottom']>&__arrow {
        top: -4px;
    }

    &__content[data-popper-placement^='left']>&__arrow {
        right: -4px;
    }

    &__content[data-popper-placement^='right']>&__arrow {
        left: -4px;
    }
}
