/*
* The Scroll Header component - found on the homepage only.
* This component handles the showing and hiding of the fixed header 
* when the user scrolls down the homepage. 
* Works in combination with javascript ScrollHeader.js
*/

.c-scrollheader {
    position: absolute;
    
    &__content {
        position: fixed;
        // Effect only required on large screens
        @include breakpoint(large) {
            top: calc(rem(-68) - 20px); // header height
            transition: top .6s ease-in-out;
        }
    }
    
    &.is-visible {
        .c-scrollheader__content {
            top: 0;
        }
    }
}