/*
* The page header. Better to contain code into own file.
* Extensive css settings found here.
*/

.l-page-header {
    background-color: cssvar(color-white);
    position: relative;
    z-index: 5;
    height: auto;
    margin: 0 0 rem(16) 0;
    padding: rem(10 0 0);
    color: cssvar(color-foreground);

    .l-grid {
        position: relative;
    }
    
    .o-contact-options { // Handles position only. The foundational props are found in the objects.scss file
        position: absolute;
        top: rem(16);
        right: 0;  
    }
    
    &--relative {
        display: none;
        
        @include breakpoint(large) {
            display: block;
        }
    }
    
    &--fixed {
        position: fixed;
        background-color: cssvar(color-primary);
        padding-bottom: rem(10);
    
        // Small screens we hide navigation until opened
        max-height: rem(72);
        transition: max-height .6s ease-in-out;
        overflow: hidden;
        
        @include breakpoint(large) {
            overflow: visible;
        }
        
        nav a {
            font-size: rem(14);
            font-weight: $global-weight-normal;
    
        }
    
        a:not(:hover),
        a:focus:not(:focus-visible) {
            color: cssvar(color-white);
        }
    
        // layout grid exceptions
        .l-grid {
            align-items: center;
        }
    
        // using small to medium screen mediaquery in order to use less code
        @include breakpoint(large, down) {
            .l-grid__cell:first-child {
                flex: 1 0 100%;
            }
    
            .l-container {
                --project-content-gutter-right: #{rem(15)};
                --project-content-gutter-left: #{rem(15)};
            }
    
            .l-grid__cell:last-child {
                align-self: baseline;
                padding-top: rem(10);
            }
        }
    }
    
    &.is-expanded {
        max-height: 100%;
    }
}


// Spacing needed if we have a fixed navigation
.l-page-content--extra-spacing-top {
    margin-top: rem(72);
}