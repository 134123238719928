@use "sass:math";

// CSS Custom Properties ("CSS Variables") in SCSS
// @see https://dev.to/felipperegazio/css-custom-properties-vars-with-sass-scss-a-practical-architecture-strategy-1m88
/**
 * Retrieve a css variable value with prefix
 *
 * Usage
 *
 * .selector {
 *   color: cssvar(color-primary);
 * }
 *
 * Will result in
 *
 * .selector {
 *    color: var(--prefix-color-primary);
 * }
 */
$cssvar-prefix: "seda" !default;

@function cssvar($name, $fallback: null, $prefix: null) {
    @if $prefix ==null {
        $prefix: $cssvar-prefix;
    }

    @return var(--#{$prefix}-#{$name}, #{$fallback});
}

/**
 * Usage
 .btn-primary {
    @include setcssvar(button-height, 56px);
 }
*/
@mixin setcssvar($name, $value: "", $prefix: null) {
    @if $prefix ==null {
        $prefix: $cssvar-prefix;
    }

    --#{$prefix}-#{$name}: #{$value};
}

@mixin setcssvars($css_variables, $prefix: null) {
    @if $prefix ==null {
        $prefix: $cssvar-prefix;
    }

    @each $name,
    $value in $css_variables {
        --#{$prefix}-#{$name}: #{$value};
    }
}

/**
 * Usage:
 @include setrootcssvars((
   base-font-size: 65.5%,
   font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif},

   primary-color: #33b5e5,
   secondary-color: #ff500a,
 ));
*/
@mixin setrootcssvars($css_variables, $prefix: null) {
    @if $prefix ==null {
        $prefix: $cssvar-prefix;
    }

    :root {

        @each $name,
        $value in $css_variables {
            --#{$prefix}-#{$name}: #{$value};
        }
    }
}

@mixin tpl($variationName) {
    @at-root html.template--#{$variationName} #{if(&, '&', '')} {
        @content;
    }
}

// Mixin to manage responsive breakpoints
@mixin breakpoint($breakpoint, $media-direction: up) {

    // Direction: we expect up or down as value. Transform that.
    $direction: min;

    @if $media-direction ==down {
        $direction: max;
    }

    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {

        $breakpoint-width: map-get($breakpoints, $breakpoint);

        // Handle case of down. We need to subtract a pixel for the boundary to be set properly
        @if $media-direction ==down and $breakpoint-width >0 {
            $strip-width: strip-unit($breakpoint-width);
            $breakpoint-width: ($strip-width - 1)+'px';
        }

        // Return
        @media (#{$direction}-width: #{$breakpoint-width}) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @if unitless($breakpoint) {
            $breakpoint: $breakpoint + 0px;
        }

        // Return
        @media (#{$direction}-width: $breakpoint) {
            @content;
        }
    }
}

@mixin show-for-only($breakpoint) {
    $lower-bound-size: map-get($breakpoints, $breakpoint);
    $upper-bound-size: -zf-map-next($breakpoints,
        $breakpoint);

    // adjust lower-bound. needs to lose a pixel
    $lower-bound-no-unit: strip-unit($lower-bound-size);
    $lower-bound-adjustment: $lower-bound-no-unit+'px'; // set default

    @if $lower-bound-no-unit >0 {
        // need to remove a pixel from lower-bound-limit for it to work with max-width settings later
        $lower-bound-adjustment: ($lower-bound-no-unit - 1)+'px';
    }

    @if $upper-bound-size ==null {

        @media screen and (max-width: $lower-bound-adjustment) {
            display: none !important;
        }
    }

    @else {

        @media screen and (max-width: $lower-bound-adjustment),
        screen and (min-width: $upper-bound-size) {
            display: none !important;
        }
    }
}

/// Hide an element by default, only displaying it above a certain screen size.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin show-for($size) {
    $size: map-get($breakpoints, $size);

    @if $size >0 {
        $strip-size: strip-unit($size);
        $size: ($strip-size - 1)+'px';
    }

    @media screen and (max-width: $size) {
        display: none !important;
    }
}

/// Find the next key in a map.
/// @access private
///
/// @param {Map} $map - Map to traverse.
/// @param {Mixed} $key - Key to use as a starting point.
///
/// @returns {Mixed} The value for the key after `$key`, if `$key` was found. If `$key` was not found, or `$key` was the last value in the map, returns `null`.
@function -zf-map-next($map, $key) {

    // Store the keys of the map as a list
    $values: map-keys($map);

    $i: 0;

    // If the Key Exists, Get the index of the key within the map and add 1 to it for the next breakpoint in the map
    @if (map-has-key($map, $key)) {
        $i: index($values, $key)+1;
    }

    // If the key doesn't exist, or it's the last key in the map, return null
    @if ($i > length($map) or $i ==0) {
        @return null;
    }

    // Otherwise, return the value
    @else {
        @return map-get($map, nth($values, $i));
    }

}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$global-font-size` variable as the base.
///
/// @returns {List} A list of converted values.
$global-font-size: 100% !default;

@function rem($values, $base: null) {
    $rem-values: ();
    $count: length($values);

    // If no base is defined, defer to the global font size
    @if $base ==null {
        $base: $global-font-size;
    }

    // If the base font size is a %, then multiply it by 16px
    // This is because 100% font size = 16px in most all browsers
    @if unit($base)=="%" {
        $base: math.div($base, 100%) * 16px;
    }

    // Using rem as base allows correct scaling
    @if unit($base)=="rem" {
        $base: strip-unit($base) * 16px;
    }

    @if $count ==1 {
        @return -zf-to-rem($values, $base);
    }

    @for $i from 1 through $count {
        $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
    }

    @return $rem-values;
}

@function em($values) {
    $values: rem($values);
    $em-values: ();
    $count: length($values);

    @for $i from 1 through $count {
        $value: nth($values, $i);
        $em-values: append($em-values, strip-unit($value) * 1em);
    }

    @return $em-values;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -zf-to-rem($value, $base: null) {

    // Check if the value is a number
    @if type-of($value) !="number" {
        @warn inspect($value)+' was passed to rem(), which is not a number.';
        @return $value;
    }

    // Transform em into rem if someone hands over 'em's
    @if unit($value)=="em" {
        $value: strip-unit($value) * 1rem;
    }

    // Calculate rem if units for $value is not rem or em
    @if unit($value) !="rem" {
        $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
    }

    // Turn 0rem into 0
    @if $value ==0rem {
        $value: 0;
    }

    @return $value;
}

@function strip-unit($num) {
    @return math.div($num, $num * 0 + 1);
}

//
//  Function to create an optimized svg url https://codepen.io/jakob-e/pen/doMoML
//
@function svg-url($svg){

//
//  Add missing namespace
//
@if not str-index($svg, xmlns) {
    $svg: str-replace($svg, "<svg", '<svg xmlns="http://www.w3.org/2000/svg"');
}

//
//  Chunk up string in order to avoid
//  "stack level too deep" error
//
$encoded: "";
$slice: 2000;
$index: 0;
$loops: ceil(str-length($svg) / $slice);

@for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, "%", "%25");
    $chunk: str-replace($chunk, "&", "%26");
    $chunk: str-replace($chunk, "#", "%23");
    $chunk: str-replace($chunk, "{", "%7B");
    $chunk: str-replace($chunk, "}", "%7D");
    $chunk: str-replace($chunk, "<", "%3C");
    $chunk: str-replace($chunk, ">", "%3E");

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
}

@return url("data:image/svg+xml,#{$encoded}");
}

// Helper function to replace characters in a string
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);
    @return if($index,
        str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
        $string );
}

@function rgba-to-rgb($rgba, $background: #fff) {
    @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%);
}
