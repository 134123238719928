@charset "UTF-8";

// http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

@import '../node_modules/modern-css-reset/src/reset';

// import helper functions and settings
@import 'functions';
@import 'settings';

// Local Imports
@import 'grid';
@import 'layout';
@import 'pageheader';
@import 'typography';
@import 'objects';
@import 'tables';
@import 'animations';

// Components
@import './components/mainnav.scss';
@import './components/mainlogo.scss';
@import './components/footernav.scss';
@import './components/slider.scss';
@import './components/scrollheader.scss';
@import './components/mobilemenu-btn';
@import './components/button';
@import './components/link';
@import './components/teamblock';
@import './components/card';
@import './components/breadcrumbs';
@import './components/advertisement';
@import './components/accordion';
@import './components/tabnav';
@import './components/callout';
@import './components/image';
@import './components/forms';
@import './components/tooltip';
@import './components/dashboard-nav';
@import './components/dashboard-booking';
@import './components/documents';
@import './components/partners';
@import './components/bookingstate';
@import './components/button-toolbar';
@import './components/dialog';
@import './components/richtext';

/**
* Recipes - components that consists of other components within the design system.
* Reference: https: //bradfrost.com/blog/post/design-system-components-recipes-and-snowflakes/
*/
@import './recipes/course-outline';

// This should come late
@import 'utils';
@import 'print';

// This should not be part of production stuff (how to exclude?)
@import 'dev';
