.dev {
  &.environment {
    font-weight: bold;
    text-align: center;
    padding: 1em 1.4em;
    color: white;
    text-shadow: 0px 0px 1px black;
    background-color: darkorange;
    margin-bottom: 1em;
  }
}

div.dev {
  border: 1px dashed gray;

  div {
    border: 1px dotted blue;
    margin: 1em 1.4em;
  }
}

todo {
  color: black;
  border: 1px solid #fff200;
  box-shadow: white 0 0 4px 2px;
  border-left: 0;
  background-color: #f3de8c;
  padding: 0 0.75em 0.5em;
  margin: 1em 0;
  display: inline-block;
  border-radius: 1em;
  position: absolute;
  width: 12em;
  font-size: 80%;
  transform: rotate(-20deg) translate(-150%, -150%);

  &:before {
    content: "TODO";
    display: block;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 0.5em 0.75em;
    background-color: #fff200;
    font-weight: bold;
    text-align: center;
    margin-left: -0.75em;
    margin-right: -0.75em;
    margin-bottom: 0.5em;
  }

  &:after {
    border-top: 3px dotted #f3de8c;
    width: 75%;
    content: " ";
    display: block;
    transform: rotate(20deg);
    left: 105%;
    position: relative;
  }
}


question {
  @extend todo;
  background-color: #6161ef;
  color: white;
  border: 1px solid #6161ef;
  transform: rotate(20deg) translate(150%, -125%);
  right: 0;

  &:before {
    content: "QUESTION ??";
    background-color: #3737da;
  }

  &:after {
    border-top: 3px dashed #6161ef;
    transform: rotate(-20deg);
    left: -75%;
    top: 6em;
    position:absolute;
    opacity: 50%;
  }
}


