@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// Animation for the accordion - open and close
@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
    }
}

// Dialog animations 
@keyframes fade-in-slide-up {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-overlay {
    from {
        opacity: 0;
        backdrop-filter: blur(0);
    }

    to {
        opacity: 1;
        backdrop-filter: blur(8px);
    }
}

// Used for loader graphic
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}