/**
* Recipe - a component that consists of other components within the design system.
* Reference: https: //bradfrost.com/blog/post/design-system-components-recipes-and-snowflakes/
*
* When an accordion gets used in this recipe it should get adjusted automatically.
* The course outline recipe is found in the elearning template -> overlay, AND it is also used in the
* Dashboard -> Course listings.
* The default version should be what is found in the overlay, and the dashboard version is a variation of that.
*/

.r-course-outline {
    &__slidecount {
        color: rgb(var(--courseoutline-color, var(--accordion-catcolor)));
    }

    // List of chapters within the outline
    &__chapters {
        list-style-type: none;
        li {
            margin: rem(5 0);
            &:last-child {
                margin-bottom: 0;
                border: none;
            }
        }

        &.c-accordion {
            // chapter items with an accordion
            .c-accordion__title {
                background-color: rgba(var(--courseoutline-color, var(--accordion-catcolor)), .5);
                color: cssvar(color-white);
            }

            .is-current,
            .is-completed {
                .c-accordion__title {
                    background-color: rgba(var(--courseoutline-color, var(--accordion-catcolor)), .5);
                    color: cssvar(color-body-font);
                }

                i {
                    background-color: cssvar(color-body-font);
                }
            }

        }
    }

    &__modules {
        .is-current,
        .is-completed {
            .c-accordion__title {
                background-color: rgba(var(--courseoutline-color, var(--accordion-catcolor)), 1);
                color: cssvar(color-white);
            }

            i {
                background-color: cssvar(color-white);
            }
        }
    }

    // Slide listing structure
    &__slides {
        list-style-type: none;
        padding: 0;

        li {
            display: flex;
            padding: rem(0 10);

            &:last-child {
                margin-bottom: $global-margin;
            }
        }

        p {
            margin: 0;
        }

        // Slide state changes
        .is-inactive {
            a, p {
                cursor: not-allowed;
                color: cssvar(color-disabled);
                text-decoration: none;
            }
        }

        .is-current {
            a, p {
                color: rgb(var(--courseoutline-color, var(--accordion-catcolor)));
                text-decoration: none;
            }
        }

        .is-completed {
            a, p {
                color: cssvar(color-body-font);
            }
        }
    }

    // layouting slide item info
    &__slideindex {
        flex: 0 0 rem(75);
    }

    &__slidename {
        flex: 1 1 auto;
    }

    .c-accordion__title {
        font-size: rem(16);
        padding: rem(5 8);
        background-color: rgb(var(--courseoutline-color, var(--accordion-catcolor)));
    }

    .c-accordion {
        > li > i {
            margin-top: rem(10);
        }
    }

    &--dashboard {
        .r-course-outline__chapters {
            padding: rem(5 8 5 20);

            p {
                color: cssvar(color-mediumgray);
            }

            .is-inactive {
                a, p {
                    cursor: not-allowed;
                    color: cssvar(color-disabled);
                    text-decoration: none;
                }
            }

            .is-current, .is-completed {
                a, p {
                    color: cssvar(color-body-font);
                }
            }
        }

        .r-course-outline__modules {
            .is-current, // states classes assigned to li
            .is-completed {
                .c-accordion__title {
                    background-color: rgba(var(--courseoutline-color, var(--accordion-catcolor)), .5);
                    color: cssvar(color-body-font);
                }

                i {
                    background-color: cssvar(color-body-font);
                }

                >input[type=checkbox]:hover {
                    &~.c-accordion__title {
                        background-color: rgba(var(--accordion-catcolor), .5);
                    }
                }
            }

            .is-inactive {
                border-color: cssvar(color-disabled) !important;

                >input[type=checkbox]:hover {
                    cursor: not-allowed;
                }

                .c-accordion__title {
                    background-color: transparent !important;
                    color: cssvar(color-disabled) !important;

                }

                i {
                    background-color: cssvar(color-disabled) !important;
                }

                .c-accordion__content {
                    background-color: transparent;
                }
            }
        }

        .c-accordion__title {
            color: cssvar(color-body-font);
            background-color: rgba(var(--courseoutline-color, var(--accordion-catcolor)), .5);
            margin-bottom: rem(2);
        }

        .c-accordion__content {
            padding-left: 0;
            background-color: rgba(var(--courseoutline-color, var(--accordion-catcolor)), .3);
        }

        .c-accordion li:last-child {
            border: none;
        }
    }
}
