.c-image {
    position: relative;
    display: block;
    width: var(--width, 100%);
    max-width: 100%;
    margin: 0 0 $global-margin;

    img,
    picture {
        display: block;
        width: var(--width, 100%);
        max-width: 100%;
        height: auto;
        aspect-ratio: var(--aspectratio, 16 / 9);
    }

    &--center {
        margin-right: auto;
        margin-left: auto;
    }

    &--right {
        margin-right: 0;
        margin-left: auto;
    }

    figcaption {
        font-size: rem(14);
        display: block;
        margin-top: rem(5);
    }
}