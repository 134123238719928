/*
* Objects are like components, but are very small and have very little functionality.
*/

.o-divider {
    margin: rem(30) auto;
    max-width: rem(200);
    border-style: solid;
    border-bottom-width: rem(1);
    border-color: cssvar(color-primary);
}

.o-course-icon {
    display: inline-block;
    svg {
        fill: var(--icon-fill, currentColor);
    }
}

.o-contact-options {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    
    li {
        margin-left: rem(0);
    }
    
    a {
        padding: $base-link-padding;
    }
    
    svg {
        width: rem(18);
        height: rem(13);
    }
}

.o-login-nav {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        white-space: nowrap;
    }
    
    li {
        flex: 0 1 auto;
        margin-left: rem(10);
    }
    
    a {
        padding: $base-link-padding;
    }
    
    &__profile-icon {
        @include breakpoint(large) {
            display: none;
        }
    }
    
    &__profile-label {
        display: none;
        @include breakpoint(large) {
            display: inline-block;
        }
    }
    
    @media all and (max-width: map-get($breakpoints, large)) {
        ul {
            display: block;
        }
        
        li {
            margin: rem(5 0);
        }
    }
}

 .o-icon-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    
    li {
        flex: 0 1 auto;
        margin-right: rem(20);
    }
 }
 
 .o-homepage-link {
    display: flex;
 }
 
 .o-loader {
    text-align: center;
    svg {
        width: rem(50);
        height: rem(50);
        fill: cssvar(color-primary);
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
 }
 
 .o-badge {
     border-radius: $global-margin;
     display: inline-block;
     border: none;
     background-color: cssvar(color-primary);
     color: cssvar(color-white);
     min-width: $global-margin;
     min-height: $global-margin;
     font-size: 70%;
     line-height: $global-margin;
     position: absolute;
     top: 0;
     right: calc($global-margin / -2);
     text-align: center;

     &--warning {
         background-color: cssvar(color-warning);
     }
 }