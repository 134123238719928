html {
    height: 100vh;
    // mobile viewport bug fix: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    height: -webkit-fill-available;
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    // mobile viewport bug fix: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    min-height: -webkit-fill-available;
    background: cssvar(color-white);
}

// this is for fixing the footer to the bottom of the viewport (because of min-height not working in IE)
.l-page-wrapper {
    min-height: 100vh; // TODO: this should again cause the mobile viewport bug I guess!
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.l-page-content {
    flex: 1 0 auto; // allow grow page-content, to fix footer to bottom
    padding-bottom: rem(50);

    transition: opacity 0.2s ease-in-out;
}

.l-page-header,
.l-page-footer {
    width: 100%; // needed for firefox and edge display error
    flex: 0 0 auto;
}

.l-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 cssvar(content-gutter-right) 0 cssvar(content-gutter-left);
    background-color: var(--grid-backgroundcolor);
    color: var(--grid-color);

    &--content {
        max-width: $global-width;
    }

    &--full {
        padding-left: 0;
        padding-right: 0;
    }
}

// Resets spacing for nested layout within a 1 column layout
.l-nested-layout-container .l-container {
    margin-left: calc(#{cssvar(content-gutter-left)} * -0.5);
    margin-right: calc(#{cssvar(content-gutter-right)} * -0.5);
    padding: 0;
}

/*
* Extensive Footer layout settings
*/

.l-page-footer {
    
    p, p a:not(:hover), address {
        color: cssvar(color-tertiary);
    }
    // TODO- check if this standard template setting is still needed   
    @include tpl(standard) {
        background-color: cssvar(color-quaternary);
    }
    
    &__navigation {
        background: cssvar(color-primary);
        padding: rem(5 0 28 0);
    }
}


/*
* Download List layout. Used for the Infothek.
*/

.l-downloadlist {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;

    li {
        // flex: 0 0 rem(265);
        margin-right: rem(16);

        &:last-child {
            margin-right: 0;
        }
    }
}

// Dashboard layout settings
.l-icon-title-grid {
    display: flex;
    align-items: center;
    margin-bottom: $global-margin;
    
    svg {
        display: block;
        width: rem(36);
        height: rem(36);
        fill: rgb(var(--themecolor));
        margin-right: rem(15);
    }

    @include breakpoint(large) {
        margin-bottom: 0;
    }
}