/*
 * Accordion component
 * highly inspired by https://inclusive-components.design/tabbed-interfaces/
 */


 .c-tabnav {
    padding: 0;
    margin-bottom: 0;

    li {
        display: inline-block;
    }

    // Needed to handle side effects of using button class
    .c-button {
        border-radius: 0;
        color: var(--button-color, #{cssvar(color-accent)});
        border-color: currentColor;

        &:focus:not(:focus-visible):hover,
        &:hover {
            &.has-outline {
                color: cssvar(color-white);
                background-color: var(--button-color, #{cssvar(color-accent)});
                border-color: var(--button-color, #{cssvar(color-accent)});
            }
        }
    }

    .c-button[aria-selected] {
        background-color: var(--button-color, #{cssvar(color-accent)});
        color: cssvar(color-white) !important;
        border-color: var(--button-color, #{cssvar(color-accent)});

        &.has-outline {
            color: var(--button-color, #{cssvar(color-accent)});
            border-color: var(--button-color, #{cssvar(color-accent)});
            background-color: var(--button-color, #{cssvar(color-accent)});
        }
    }

    &__panel {
        @include breakpoint(large) {
            padding: $global-margin 0;
        }
    }

    @include breakpoint(medium, down) {

        li {
            display: block;
            margin-bottom: calc($global-margin / 2);
        }
    }

 }
