
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Roboto'),
        url('roboto-regular-webfont.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('roboto-regular-webfont.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Roboto Condensed Regular'),
        url('robotocondensed-regular-webfont.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('robotocondensed-regular-webfont.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('Roboto Condensed Bold'),
        url('robotocondensed-bold-webfont.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('robotocondensed-bold-webfont.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: local('Roboto Condensed Italic'),
        url('robotocondensed-italic-webfont.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('robotocondensed-italic-webfont..woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: local('Roboto Condensed Bold Italic'),
        url('robotocondensed-bolditalic-webfont.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('robotocondensed-bolditalic-webfont.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


$sizesIncrement: 1.12;
$fontSize0: 16;
$fontSize1: $fontSize0;
$fontSize2: $fontSize0;
$fontSize3: 20;
$fontSize4: 22;
$fontSize5: 28;
$fontSize6: 34;

@include setrootcssvars((lineHeightFixedAmount: 0.25rem,
        lineHeightRelativeAmount: 1.16em,
        sizesIncrement: $sizesIncrement,

        fontSize-6: round($fontSize6),
        fontSize-5: round($fontSize5),
        fontSize-4: round($fontSize4),
        fontSize-3: round($fontSize3),
        fontSize-2: round($fontSize2),
        fontSize-1: round($fontSize1),
        fontSize-0: round($fontSize0),

        // trick from https://twitter.com/hihayk/status/1280979972258172928
        // nice tool for this at https://hihayk.github.io/doppler/
        globalLineHeight: calc(cssvar(lineHeightFixedAmount) + cssvar(lineHeightRelativeAmount)),
    ));

* {
    line-height: cssvar(globalLineHeight);
}

html {
    font-size: 100%;
}

body {
    font-size: 1rem;
    font-family: cssvar(body-font-family);
    font-weight: $global-weight-normal;
    accent-color: cssvar(color-accent);
    color: cssvar(color-body-font);
}


h1,
h2,
h3,
h4,
h5,
h6,
.o-headline--size1,
.o-headline--size2,
.o-headline--size3,
.o-headline--size4,
.o-headline--size5,
.o-headline--size6 {
    margin: 0.5rem 0;
    color: var(--heading-color, currentColor);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: cssvar(header-font-family);
    font-weight: $global-weight-normal;
    letter-spacing: cssvar(header-font-spacing);
    line-height: 1.4;
}



// Fluid type settings
// https: //dev.to/ixkaito/fluid-typography-using-css-custom-properties-css-variables-3lcn
/**
* Available vars:
* @var --viewport-from: <number> - Number in pixels without the unit. Required if `--font-size` is not exist.
* @var --viewport-to: <number> - Number in pixels without the unit. Required if `--font-size` is not exist.
* @var --font-size-from: <number> - Number in pixels without the unit. Required if `--font-size` and `--min-font-size` is not exist.
* @var --font-size-to: <number> - Number in pixels without the unit. Required if `--font-size` and `--max-font-size` is not exist.
* @var --max-font-size: <number> - Number in pixels without the unit. Optional.
* @var --min-font-size: <number> - Number in pixels without the unit. Optional.
* @var --viewport-unit-converter: 1vw | 1vh | 1vmin | 1vmax - Optional. Default: 1vw.
* @var --font-size: <length> | <percentage> | <absolute-size> | <relative-size> | Global values - Optional.
*/
h1,
h2,
h3,
h4,
h5,
h6,
.o-headline--size1,
.o-headline--size2,
.o-headline--size3,
.o-headline--size4,
.o-headline--size5,
.o-headline--size6 {
    --viewport-unit-converter: 1vw;
    --fz-from: var(--font-size-from, var(--min-font-size));
    --fz-to: var(--font-size-to, var(--max-font-size));
    --fz-slope: (var(--fz-to) - var(--fz-from)) / (var(--viewport-to) - var(--viewport-from)) * 100;
    --fz-intercept: (var(--viewport-to) * var(--fz-from) - var(--viewport-from) * var(--fz-to)) / (var(--viewport-to) - var(--viewport-from));
    --font-size: calc(var(--fz-slope) * var(--viewport-unit-converter) + var(--fz-intercept) * 1px);

    --min-fz-px: calc(var(--min-font-size) * 1px);
    --max-fz-px: calc(var(--max-font-size) * 1px);
    --clamp: clamp(var(--min-fz-px), var(--font-size), var(--max-fz-px));
    --max: var(--has-max, var(--min));
    --min: var(--has-min, var(--font-size));
    --has-max: min(var(--max-fz-px), var(--font-size));
    --has-min: max(var(--min-fz-px), var(--font-size));
    --global-viewport-from: 375;
    --global-viewport-to: 1020;
    --font-text-transform: normal;
    font-size: var(--clamp, var(--max));
    text-transform: var(--font-text-transform);
}


h1,
.o-headline--size1 {
    --viewport-from: var(--global-viewport-from);
    --min-font-size: 24;
    --viewport-to: var(--global-viewport-to);
    --max-font-size: #{cssvar(fontSize-6)};
    font-family: 'Roboto', cssvar(header-font-family);
}

h2,
.o-headline--size2 {
    --viewport-from: var(--global-viewport-from);
    --min-font-size: 20;
    --viewport-to: var(--global-viewport-to);
    --max-font-size: #{cssvar(fontSize-5)};
    font-family: 'Roboto', cssvar(header-font-family);
}

h3,
.o-headline--size3 {
    --viewport-from: var(--global-viewport-from);
    --min-font-size: 19;
    --viewport-to: var(--global-viewport-to);
    --max-font-size: #{cssvar(fontSize-4)};
    --font-text-transform: uppercase;
    margin-bottom: 1rem;
}


h4,
.o-headline--size4 {
    --viewport-from: var(--global-viewport-from);
    --min-font-size: 18;
    --viewport-to: var(--global-viewport-to);
    --max-font-size: #{cssvar(fontSize-3)};
}



h5,
.o-headline--size5 {
    --viewport-from: var(--global-viewport-from);
    --min-font-size: 16;
    --viewport-to: var(--global-viewport-to);
    --max-font-size: #{cssvar(fontSize-2)};
}

h6,
.o-headline--size6 {
    --viewport-from: var(--global-viewport-from);
    --min-font-size: 16;
    --viewport-to: var(--global-viewport-to);
    --max-font-size: #{cssvar(fontSize-1)};
}


p {
    margin: 0 0 $global-margin;
    line-height: 1.6;
}


li {
    line-height: 1.6;
    
    ul {
        list-style-type: disc;
    }
}
// Focus and hover effects only for devices with fine pointers. This excludes touch devices.
// Touch devices keep focus style after they have been used and do not reset back to original state.
a,
a:focus:not(:focus-visible),
button:not(.c-button),
button:focus:not(.c-button):not(:focus-visible) {
    color: cssvar(color-primary);
    transition: color 0.3s ease-in-out;
    outline-color: cssvar(color-accent); 
    @media (pointer: fine) {
        &:hover,
        &:focus-visible {
            color: cssvar(color-accent);
        }
    }
}



// show href value when a tag has no text value (https://twitter.com/AllThingsSmitty/status/946381631836901377)
a[href^="http"]:empty::before {
    content: attr(href);
}

// SVGs in current font color
svg {
    fill: currentColor;
    transition: fill .3s ease-in-out;
}

address {
    font-style: normal;
}

blockquote {
    display: block;
    margin: rem(5 0);
    font-size: rem(13);
    font-style: italic;
    color: cssvar(color-mediumgray);
}