/*
* Footer nav found at the bottom of the website
*/

.c-footernav {
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
    }
    
    a {
        text-decoration: none;
        font-size: rem(13);
        padding: $base-link-padding;
    }
    
    a:not(:hover) {
        color: cssvar(color-white);
    }
}