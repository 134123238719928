/*
* Card component styling
*/

.c-card {
    background-color: cssvar(color-accent);
    padding: rem(15);
    margin-bottom: $global-margin;
    
    &__title {
        font-weight: $global-weight-bold;
    }
    
    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    &__header {
        flex: 0 1 auto;
    }
    
    &__footer {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    
    // Dashboard variation 
    &--dashboard {
        background-color: transparent;
        border: none;
        padding: 0;
        
        .c-card__header, .c-card__footer {
            padding: rem(10 15);
            background-color: cssvar(color-quaternary);
        }
        
        .c-card__content {
            padding: rem(15);
            margin: rem(3 0);
            border: rem(1) solid cssvar(color-quaternary);
        }
        
        hr {
            height: rem(1);
            width: 100%;
            display: block;
            margin: rem(20) 0;
            border-top: rem(1) solid cssvar(color-quaternary);
        }
    }
    
    // Download card variation
    &--download {
        background-color: transparent;
        border: rem(1) solid cssvar(color-accent);
        padding: 0;
        
        // Only when we have a mouse or keyboard do we need hover and focus state
        @media (pointer: fine) {
            &:hover, &:focus {
                .c-card__download-cta {
                    background-color: cssvar(color-primary);
                }
            }
        }
    }
    
    // Call to action section
    &__download-cta {
        background-color: cssvar(color-accent);
        transition: background .3s ease-in-out;
        // margin: 0 rem(-15) rem(-15);
        padding: rem(16);
        color: cssvar(color-white);
    }
}

.c-newscards {
    max-width: rem(784);
    margin: $global-margin auto;
    color: cssvar(color-white);
    
    time {
        display: inline-block;
        font-size: rem(13);
        margin-bottom: $global-margin;
    }
}
