/* ==========================================================================
   Accordion component
   ========================================================================== */

/*
 * highly inspired by https://codepen.io/abergin/pen/ihlDf
 */

.flipIn {
    animation: flipdown 0.5s ease both;
}

.c-accordion {
    list-style: none;
    padding: 0;
    margin: $global-margin 0;
    text-align: left; // if accordion inside of container with text-align: right, the accordion no longer works

    >li {
        position: relative;
        margin: 0;
        border-bottom: 1px solid rgb( var(--accordion-catcolor) );
        transition: height 0.3s ease-in-out;

        @extend .flipIn;

        @for $i from 1 through 20 {
            &:nth-of-type(#{$i}) {
                animation-delay: #{$i * 0.125}s;
            }
        }

        >i {
            position: absolute;
            z-index: 1;
            right: rem(20);
            background-color: cssvar(color-white);
            margin-top: rem(26);

            &:before,
            &:after {
                content: "";
                transition: all 0.25s ease-in-out;
                position: absolute;
                background-color: inherit;
                width: rem(3);
                height: rem(13);
            }

            &:before {
                transform: translate(-4px, 0) rotate(45deg);
            }

            &:after {
                transform: translate(3px, 0) rotate(-45deg);
            }
        }

        >input[type=checkbox] {
            position: absolute;
            cursor: pointer;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;

            @media only screen {
                // Checked is the closed state
                &:checked {
                    &~.c-accordion__title {
                        color: cssvar(color-body-font);
                        background-color: transparent;

                        svg {
                            fill: rgb(var(--accordion-catcolor));
                        }
                    }

                    &~.c-accordion__content {
                        margin: 0;
                        visibility: hidden;
                        max-height: 0;
                        opacity: 0;
                    }

                    &~i {
                        background-color: rgb(var(--accordion-catcolor));
                        &:before {
                            transform: translate(3px, 0) rotate(45deg);
                        }

                        &:after {
                            transform: translate(-4px, 0) rotate(-45deg);
                        }
                    }
                }
            }

            &:hover {
                &~.c-accordion__title {
                    color: cssvar(color-white);
                    background-color: rgba(var(--accordion-catcolor), 1);

                    svg {
                        fill: cssvar(color-white);
                    }
                }

                &~i {
                    background-color: cssvar(color-white);
                }
            }
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        padding: rem(10 20);
        margin: 0;
        cursor: pointer;
        font-size: rem(20);
        transition: color 0.25s ease-in-out, background .25s ease-in-out;
        color: cssvar(color-white);
        background-color: rgba(var(--accordion-catcolor), 1);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        user-select: none;

        .c-accordion__title-label {
            display: block;
            flex: 0 1 auto;
            line-height: rem(48);
        }
        // when accordion is open, we need the svg to be white because the background gets a color
        svg {
            fill: cssvar(color-white);
        }

        .c-accordion__title-icon {
            display: flex;
            align-items: center;
            flex: 0 0 rem(36);
            margin-right: rem(36);
        }
    }

    // Button
    .c-button {
        --button-backgroundcolor: rgb(var(--accordion-catcolor));
    }

    // Dashboard variation
    &--dashboard {

        .c-accordion__title {
            padding: rem(10 5);

            svg {
                width: rem(40);
                height: rem(40);
                margin-right: rem(15);
            }

            p {
                font-size: rem(16);
            }

            .l-container {
                margin-right: rem(30); // spacing for arrow button
            }

            .l-grid {
                @include breakpoint(large) {
                    align-items: center;
                }
            }

            .l-grid__cell {
                --project-grid-gutter: #{rem(8)};
            }

            .c-button {
                position: relative;
                z-index: 10;
                border: rem(1) solid cssvar(color-white);
            }
        }

        .c-accordion__content {
            padding-left: 0;

            @include breakpoint(large) {
                padding-left: rem(64); // this may need to be directly associated with the accordion markup, in case we need this component somewhere else
            }
        }
    }

    // Item class specific for year listing on the dashboard
    .c-accordion__item-courses-year {
        border-bottom-color: cssvar(color-white);

        > input[type=checkbox]:checked~.c-accordion__title {
            background-color: cssvar(color-quaternary);
            color: cssvar(color-primary);
        }

        .c-accordion__title {
            padding-left: rem(10);
        }
    }

    &__content {
        position: relative;
        opacity: 1;
        max-height: 100%;
        z-index: 2;
        transition: max-height 0.5s ease-in-out, opacity 0.65s ease-in-out;

        &--add-bgcolor {
            background-color: rgba(var(--accordion-catcolor), .2);
            // adds spacing between table and outside box
            table {
                padding: rem(20);
            }
        }

        &--add-list-chevrons {
            ul {
                @extend .u-list-chevrons;

                ul {
                    padding-left: rem(16);
                }
            }
        }

        .has-extra-spacing {
            padding: rem(20);
        }
    }

    &__sub-category {
        list-style-type: none;
        padding: 0;
    }

    &__sub-category-label {
        color: cssvar(color-white);
        background-color: rgba( var(--accordion-catcolor), .65);
        padding: rem(10 20);
    }

    &__course-list {
        background-color: rgba( var(--accordion-catcolor), .2);
        list-style-type: none;
        padding: 0;
    }

    &__course {
        padding: rem(10 10 10 10);
    }
    &__course:not(:last-child) {
        border-bottom: 1px solid rgb( var(--accordion-catcolor) );
    }

    // Captin within image gallery
    &--caption {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        background-color: cssvar(color-black);
        opacity: .9;

        .c-accordion__title, .c-accordion__content{
            color: cssvar(color-white) !important;
        }
        i {
            background-color: cssvar(color-white) !important;
            margin-top: rem(20) !important;
        }

        .c-accordion__title {
            padding: rem(15);
        }

        .c-accordion__content {
            margin: rem(0 15);
        }

        .c-accordion__title-label {
            line-height: initial;
        }
    }
}


