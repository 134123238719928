@media print {
    * {
        clip-path: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
        background: transparent !important;
        color: #000 !important;
    }

    @page {
        size: A4;
        margin: 1cm 1cm 0.5cm;
    }

    html {
        font-size: 78%;
    }

    header,
    footer {
        display: none;
    }

    h3,
    h4 {
        page-break-after: avoid; // these headlines need most of the time the content after themselves
    }

    a {
        text-decoration: none !important;
    }

    a[href]:after {
        content: none !important;
    }

    .grid-container {
        max-width: none;
    }

    //uncomment/replace class names if needed/applicable

    // print header
    // .print-logo {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     display: block !important;
    //     width: 120px;
    //     height: auto;
    // }

    // .pagenav, .hero, .pagefooter, .footer-divider {
    //     display: none; 
    // }

    // .breadcrumbs a:after {
    //     content: "";
    //     content: none;
    //     display: none;
    // }
}