//button resets
button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.c-button-group {
    display: flex;

    &--left {
        justify-content: flex-start;
        .c-button {
            margin-left: 0;
            margin-right: $global-margin;
        }
    }

    &--right {
        justify-content: flex-end;
        .c-button {
            margin-left: $global-margin;
            margin-right: 0;
        }
    }
}

.c-button {
    position: relative;
    display: flex;
    place-content: center;
    align-items: center;
    max-width: var(--button-maxwidth, none);
    width: var(--button-width, fit-auto);
    margin-bottom: $global-margin;
    padding: rem(10 18);
    font-family: cssvar(header-font-family);
    letter-spacing: cssvar(header-font-spacing);
    line-height: rem(22);
    font-weight: $global-weight-bold;
    color: var(--button-color, cssvar(color-white));
    background-color: var(--button-backgroundcolor, cssvar(color-primary));
    border-radius: $global-radius;
    cursor: pointer;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: filter .3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    // Focus and hover effects only for devices with fine pointers. This excludes touch devices.
    // Touch devices keep focus style after they have been used and do not reset back to original state.
    @media (pointer: fine) {

        &:focus:not(:focus-visible) {
            color: var(--button-color, cssvar(color-white));
            background-color: var(--button-backgroundcolor, cssvar(color-primary));

            &.has-outline {
                color: var(--button-hovercolor, cssvar(color-secondary));
                border-color: var(--button-hovercolor, cssvar(color-secondary));
                background-color: transparent;
            }
        }

        &:focus:not(:focus-visible):hover,
        &:hover,
        &:focus-visible {
            color: var(--button-color, cssvar(color-white));
            filter: brightness(var(--button-hoverbrightness, 115%));

            &.has-outline {
                color: var(--button-hoverbackgroundcolor, cssvar(color-secondary));
                border-color: var(--button-hoverbackgroundcolor, cssvar(color-secondary));
                background-color: transparent;
            }
        }

    }

    &--centered {
        margin-inline: auto;
    }

    &--secondary {
        --button-backgroundcolor: #{cssvar(color-secondary)};
        --button-color: #{cssvar(color-white)};

        --button-hovercolor: #{cssvar(color-white)};
        --button-hoverbackgroundcolor: #{cssvar(color-primary)};

        &.has-outline {
            --button-color: #{cssvar(color-secondary)};
            --button-hovercolor: #{cssvar(color-primary)};
        }
    }

    &--accent {
        --button-backgroundcolor: #{cssvar(color-accent)};
        --button-color: #{cssvar(color-white)};

        --button-hovercolor: #{cssvar(color-white)};
        --button-hoverbackgroundcolor: #{cssvar(color-primary)};

        &.has-outline {
            --button-color: #{cssvar(color-accent)};
            --button-hovercolor: #{cssvar(color-primary)};
        }
    }

    &--white {
        --button-backgroundcolor: #{cssvar(color-white)};
        --button-color: #{cssvar(color-primary)};
    }

    &--transparent {
        --button-backgroundcolor: transparent;
        --button-color: currentColor;
        --button-hovercolor: currentColor;
        --button-hoverbackgroundcolor: transparent;
    }

    &--textleft {
        place-content: start;
        text-align: left;
    }

    &.disabled, &.is-disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &.has-outline {
        border-style: solid;
        border-width: rem(2);
        color: var(--button-color, cssvar(color-primary));
        background: transparent;
    }

    &__icon {
        width: rem(16);
        height: rem(16);
        margin-right: rem(7);
    }

    &--inline {
        display: inline-block;
    }

    &--fullwidth {
        width: 100%;
    }

    &--standard {
        font-size: rem(16);
    }

    &--small {
        font-size: rem(13);
    }

    @media print {
        background: none;
        color: #000;
        border: 1px solid #000000;
        box-shadow: none;
    }
}



// Layout settings
.l-button {
    display: flex;
    justify-content: var(--button-justify-content, center);
}
