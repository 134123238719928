/*
* Dashboard nav component
* Found on the user dashboard in the logged in area
*/

.c-dashboard-nav {
    background-color: cssvar(color-quaternary);
    margin-top: rem(-5); // header has built in spacing that we need to deal with
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        // scrolling - effective on small screens. No side effects on large screens.
        width: auto;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        scrollbar-width: none;
    
        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    li {
        flex: 0 1 auto;
        margin-right: rem(35);
        scroll-snap-align: start;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    a {
        display: block;
        padding: rem(15 6 10 6);
        text-decoration: none;
        border-bottom: rem(5) solid transparent;
        white-space: nowrap;
    }
    
    .is-active {
        border-bottom-color: cssvar(color-primary);
    }
    
    // Small to medium screen layout changes- using small to medium screen mediaquery in order to use less code
    @include breakpoint(large, down) {
        .l-grid__cell:first-child {
            flex: 1 0 100%;
        }

        .l-container {
            --project-content-gutter-right: #{rem(15)};
            --project-content-gutter-left: #{rem(15)};
        }

        .l-grid__cell:last-child {
            padding-top: rem(10);
        }
    }
}