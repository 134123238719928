/* ==========================================================================
   Utility classes
   - Flex order based on breakpoints
   - Visibility
   - Spacing
   - Typography
   - display
   - Icons
   - Miscellaneous
   ========================================================================== */


// Element flex ordering based on media queries

// TODO this is not being used in MODX anywhere. Delete it? Would save us a lot of lines of code
// @each $breakPointName,
//$breakpointValue in $breakpoints {
//    @include breakpoint($breakPointName) {
//         @for $idx from 1 through $grid-column-count {
//              .#{"u-" + $breakPointName + "-" + "order-" + $idx} {
//                 order: #{$idx};
//             }
//          }
//     }
//  }

/*
 * Visibility State Classes
  - overflow
  - Screen reader only class
  - Hide classes
  - Show classes
  - Show Only classes
*/

.u-show-overflow {
    overflow: visible !important;
}

.u-sr-only {
    // ref. https: //css-tricks.com/inclusively-hidden/
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%); // future browsers - clip will be deprecated
    white-space: nowrap;
    border-width: 0;
}


/*
*  Hide Classes
*/

.u-hide {
    display: none !important;
}

// hide medium up
.u-hide-medium {
    @include breakpoint(medium) {
        display: none !important;
    }
}

// hide large up
.u-hide-large {
    @include breakpoint(large) {
        display: none !important;
    }
}

/*
*  Show Classes
*/

// medium up
.u-show-medium {
    // === u-hide-small-only
    @include show-for(medium);
}

// large up
.u-show-large {
    // === u-hide-small-medium-only
    @include show-for(large);
}


/*
*  Spacing classes
*/
@media not print {

    .u-no-margin {
        margin: 0 !important;
    }

    .u-no-margin-top {
        margin-top: 0 !important;
    }

    .u-no-margin-bottom {
        margin-bottom: 0 !important;
    }

    .u-no-margin-left {
        margin-left: 0 !important;
    }

    .u-no-margin-right {
        margin-right: 0 !important;
    }

    .u-no-padding {
        padding: 0 !important;
    }

    .u-no-padding-top {
        padding-top: 0 !important;
    }

    .u-no-padding-bottom {
        padding-bottom: 0 !important;
    }

    @each $name,
    $size in $extra-class-sizes {
        .u-extra-#{$name}-margin {
            margin: $size !important;
        }

        .u-extra-#{$name}-margin-y {
            margin-top: $size !important;
            margin-bottom: $size !important;
        }

        .u-extra-#{$name}-margin-top {
            margin-top: $size !important;
        }

        .u-extra-#{$name}-margin-right {
            margin-right: $size !important;
        }

        .u-extra-#{$name}-margin-bottom {
            margin-bottom: $size !important;
        }

        .u-extra-#{$name}-margin-left {
            margin-left: $size !important;
        }

        .u-extra-#{$name}-padding {
            padding: $size !important;
        }

        .u-extra-#{$name}-padding-top {
            padding-top: $size !important;
        }

        .u-extra-#{$name}-padding-right {
            padding-right: $size !important;
        }

        .u-extra-#{$name}-padding-bottom {
            padding-bottom: $size !important;
        }

        .u-extra-#{$name}-padding-left {
            padding-left: $size !important;
        }
    }
}

/*
* Typography Helpers
*/

.u-line-clamp {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    line-clamp: var(--maxlines-small, 1);
    -webkit-line-clamp: var(--maxlines-small, 1);

    @include breakpoint(medium) {
        line-clamp: var(--maxlines-medium, var(--maxlines-small, 1));
        -webkit-line-clamp: var(--maxlines-medium, var(--maxlines-small, 1));
    }
}

.u-text-center {
    text-align: center;
}

.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align: right;
}

.u-text-uppercase {
    text-transform: uppercase;
}

.u-link-no-decoration {
    text-decoration: none;
    position: relative;
}

.u-italic {
    font-style: italic;
}

.u-strong {
    font-weight: $global-weight-bold;
}

.u-color {
    color: var(--color, cssvar(color-primary));
}

.u-text-success {
    color: cssvar(color-success);

    &:hover {
        color: cssvar(color-success);
    }
}

.u-list-chevrons {
    list-style-type: '»';
    padding-inline-start: rem(7);

    li {
        padding-left: rem(8);
    }

}

.u-text-medium {
    font-size: 0.938rem;
}

.u-leading-4\.25 {
    line-height: rem(21);
}

.u-text-mediumgray {
    color: cssvar(color-mediumgray);
}

.u-text-darkgray {
    color: cssvar(color-darkgray);
}

.u-text-darkergray {
    color: cssvar(color-darkergray);
}

.u-text-error {
    color: cssvar(color-error);

    &:hover {
        color: cssvar(color-error);
    }
}

.u-text-alert {
    color: cssvar(color-alert);

    &:hover {
        color: cssvar(color-alert);
    }
}

.u-list-reset {
    margin: 0;
    padding: 0;
    list-style-type: none;
}


/*
* Display
*/
.u-inline-block {
    display: inline-block !important;
}

/*
* Icons
*/

.u-icon {
    max-width: var(--icon-size-w, rem(32));
    max-height: var(--icon-size-h, rem(32));
    fill: var(--icon-fill, currentColor);
    width: 100%;
    height: 100%;
}


/*
* Misc
*/

.u-w-full {
    width: 100%;
}

.u-w-content {
    max-width: 100%;
}

a.u-faux-block-link-overlay {
    z-index: 10;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    text-indent: 200%;
    white-space: nowrap;
    background: transparent;
    cursor: pointer;
}


.u-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    // To discuss- when we use pointer-events: none, no cursor effect happens.
    // Not visually optimal. Consider removing the pointer-events attribute.
    // Downside is that the html would need to not be a link, so that crawlers don't use the link
    pointer-events: none;
}

.u-column-gap-12 {
    column-gap: rem(12);
}

.u-label {
    background-color: var(--color, cssvar(color-info));
    font-size: 80%;
    display:inline-block;
    padding: 0.15rem 0.5rem;
    color: var(--color, cssvar(color-lightestgray));
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: calc($global-radius * 0.75);
    border: none;

    &--warning {
        background-color: cssvar(color-warning);
        color: cssvar(color-white);
    }

    &--success {
        background-color: cssvar(color-success);
        color: cssvar(color-white);
    }

    &--error {
        background-color: cssvar(color-error);
        color: cssvar(color-white);
    }
}

.u-row {
    position:relative;
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    &--stretch {
        align-items: stretch;
    }

    &--left {
        justify-content: flex-start;
    }

    &--right {
        justify-content: flex-end
    }

    &--fullwidth {
        flex: 1 1 100%;
        margin-top: $global-margin;
    }

    > * {
        margin-right: 1.4rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.u-column {
    @extend .u-row;
    flex-direction: column;
}

p .u-label {
    font-size: 80%;
    transform: translateY(-10%);
    padding: 0 calc($global-padding/4);
    margin: 0 rem(0);
}

.todo {
    border: 1px dotted var(--color, cssvar(color-primary));
    padding: rem(15) rem(30);
}

.u-confirmemail {
    border: 0 none !important;
    clip: rect(0, 0, 0, 0) !important;
    height: 1px !important;
    margin: -1px !important;
    margin-bottom: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
