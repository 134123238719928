// Global prefix for CSS Custom Properties (automagically added)
$cssvar-prefix: 'project';

// Typography
@include setrootcssvars((body-font-family: ('Roboto Condensed', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif),
        header-font-family: cssvar(body-font-family),
        header-font-spacing: normal,
        form-bg: cssvar(color-white),
        form-gray: cssvar(color-darkgray),
        form-accent: cssvar(color-primary),
        border-radius: rem(6),
    ));

$global-weight-normal: 400;
$global-weight-bold: 700;

// Base Settings
$global-width: rem(1200);
$global-margin: rem(16);
$global-padding: rem(16);
$global-radius: rem(6);
$box-shadow: 0px 0px 10px 2px rgba(15, 23, 42, 0.06), 0px 4px 6px -1px rgba(15, 23, 42, 0.1);
$base-link-padding: rem(6 10);

// Global Responsive Breakpoints
$breakpoints: (
    small: 0,
    medium: 600px,
    large: 1020px,
);
$media-direction: 'min';

//sizes
$typogrid: 0.6rem;
$extra-class-sizes: (
    "small": $typogrid * 1,
    "medium": $typogrid * 2,
    "large": $typogrid * 3,
    "xlarge": $typogrid * 4,
    "xxlarge": $typogrid * 6,
    "grid": calc(cssvar(grid-gutter) / 2),
);

// Color Palette (as CSS Custom Properties, prefixed)
@include setrootcssvars((color-white: #fff,
        color-black: #222,
        color-success: #01a01c,
        color-warning: #ec7723,
        color-error: #b90505,
        color-alert: #dc2d4b,
        color-info: #334155,
        color-lightestgray: #FAFAFA,
        color-mediumlightgray: #f0f0f0,
        color-lightgray: #dedede,
        color-mediumgray: #83929B,
        color-darkgray: #657277,
        color-darkergray: #334155,
        color-facebook: #3b5998,
        color-instagram: #e1306c,
        color-youtube: #ff0000,
        color-vimeo: #1ab7ea,
        color-whatsapp: #25D366,
        color-linkedin: #0077b5,
        color-disabled: #999999,
        color-xing: #026466,
        color-background: cssvar(color-white),
    ));

// Grid / Content-Container
$grid-column-count: 12;
@include setrootcssvars((content-gutter-left: unquote('max(15px, env(safe-area-inset-left))'),
        content-gutter-right: unquote('max(15px, env(safe-area-inset-right))'),
        grid-gutter: 10px,
    ));

@include breakpoint(medium) {
    @include setrootcssvars((content-gutter-left: unquote('max(30px, env(safe-area-inset-left))'),
            content-gutter-right: unquote('max(30px, env(safe-area-inset-right))'),
            grid-gutter: 30px,
        ));
}