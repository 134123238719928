/**
* Mobile Menu button [Hamburger]
*/

.c-mobilemenu-btn {
    position: absolute;
    top: rem(12);
    right: rem(12);
    z-index: 10;
    
    @include breakpoint(large) {
        display: none;
    }
    
    width: rem(30);
    height: rem(30);    
    transform: rotate(0deg);
    
    span {
        display: block;
        position: absolute;
        height: rem(3);
        width: 100%;
        background: cssvar(color-white);
        border-radius: $global-radius;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
        top: 10px;
    }

    span:nth-child(4) {
        top: 20px;
    }

    &.is-open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    &.is-open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &.is-open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &.is-open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}