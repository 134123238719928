/*
* Main logo found in the header
*/

.c-mainlogo {
    width: 100%;
    height: rem(136);
    max-width: rem(220);
    
    &--small {
        height: rem(48);
        max-width: rem(39);
    }
    
    @include tpl(laniv) {
        height: rem(92);
        margin: rem(16 0 9 0);
        &.c-mainlogo--small {
            margin: 0;
            height: rem(48);
            max-width: 100%;
        }
    }
}

// Logos have different sizes, plus I needed to specifically define dimensions here for Firefox
 img.c-mainlogo--berliner-fortbildungen-de {
     height: rem(144);
 }
