.c-promotion {
    position: relative; // needed for the abs pos link within it
    background-color: rgb(var(--ad-accent-color));
    transition: background .3s ease-in-out;
    padding: rem(2);

    figcaption {
        padding: rem(12 14);
        color: cssvar(color-white);
        text-align: center;
    }

    img {
        width: 100%;
        height: auto;
    }

    @media (pointer: fine) {
        &:hover {
            background-color: rgba(var(--ad-accent-color), .8);
        }
    }

}
