/*
* Documents component
* - Seen in the user dashboard, inside of the course list accordions
* - component elements are nested ul li
*/

.c-documents {
    list-style-type: none;
    margin-bottom: rem(1);
    padding: 0;

    &__heading {
        padding: rem(5 8);
        margin-bottom: rem(1);
        margin-top: rem(1);
        font-size: rem(16);
        background-color: rgba(var(--document-background-color, var(--accordion-catcolor)), .5);
    }

    &__files {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: rgba(var(--document-background-color, var(--accordion-catcolor)), .2);

        li {
            padding: rem(5 8);
            font-size: rem(14);
        }

        svg {
            margin: rem(0 6 0 0);
        }

        a {
            color: cssvar(color-body-font);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
