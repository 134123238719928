.c-form {
    $topPadding: rem(0);
    $inlinePadding: rem(20);

    position: relative;
    margin-bottom: $global-margin * 2;

    fieldset {
        margin: 0;
        padding: 0;
        border: none;
    }

    /* generally, paragraphs in forms should not flow as fields */
    p {
        width: 100%;
        line-height: 125%;
    }

    .c-fieldset {
        position: relative;
        display: block;
        align-items: stretch;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: $global-margin;

        &__highlight {
            background-color: var(--project-color-quaternary);
            padding: calc($global-padding * 2) calc($global-padding * 1.41) $global-padding;

            legend {
                top: calc($global-padding * 2);
                position: relative;
                display: block;
            }
        }

        .c-form__field {
            flex: 1 1 auto;

            &--fullwidth {
                flex: 1 1 100%;
                margin-top: $global-margin;
            }

        }

        &--row {
            display: flex;
            flex-direction: row;
            flex: 1 1 auto;
            > * {
                margin-right: 1.4rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &--column {
            display: flex;
            flex-direction: column;
        }

        legend {
            @extend h4;

            padding-inline-start: 0; // prevent user agent doing a 2px padding here
            padding-block-start: 0; // prevent user agent doing a 2px padding here
        }

    }

    &__message {
        margin: $global-margin 0;
        padding: $inlinePadding;
        color: var(--form-accent, cssvar(form-accent));
        border-radius: 0;
        border: rem(1) solid cssvar(color-secondary);

        &--error {
            color: cssvar(color-error);
            border-color: cssvar(color-error);
        }

        &--success {
            color: cssvar(color-success);
            border-color: cssvar(color-success);
        }

        & p:last-child {
            margin-bottom: 0px;
        }
    }

    &__submitbutton, &__cancelbutton {
        @extend .c-button;
    }

    &__cancelbutton {
        @extend .c-button--secondary;
    }

    &__field {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: $global-margin;
        overflow-x: hidden;
    }


    &__label, label {
        display: block;
        font-weight: $global-weight-normal;
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: currentColor;
        margin-bottom: calc($global-margin / 8);
    }

    &__optionallabel {
        color: var(--form-gray, cssvar(form-gray));
        font-weight: $global-weight-normal;
        font-size: 0.875em;
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }

    &__fieldhint {
        display: block;
        color: var(--form-gray, cssvar(form-gray));
        font-size: 0.875em;
        line-height: 1.25;
        margin-bottom: calc($global-margin / 4);
    }

    &__fielderror {
        display: block;
        color: cssvar(color-error);
        font-weight: $global-weight-bold;
        font-size: rem(15);
    }

    &__helper {
        margin-bottom: 0;
        color: var(--form-gray, cssvar(form-gray));
        font-style: italic;
    }

    input:not([type="radio"]):not([type="checkbox"]):not([type="file"]),
    textarea,
    select {
        position: relative;
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: rem(7 5);
        border: rem(1) solid var(--form-accent, cssvar(form-gray));
        background-color: var(--form-input-bkgd-color, transparent);
        line-height: 1.3;
        color: currentColor;
        font-weight: $global-weight-normal;
        border-radius: 0;

        &::placeholder {
            font-weight: $global-weight-normal;
            color: var(--form-gray, cssvar(form-gray));
        }

        &:focus {
            outline: currentColor solid rem(2);
            outline-offset: rem(-2);
        }

        &:disabled {
            opacity: 0.5;
        }

    }

    // Custom Filetype form field
    &__field--filetype {

        label {
            @extend .c-button;
            color: cssvar(color-white);
            display: inline-block;
            width: fit-content;
        }

        input:is([type="file"]) {
            visibility: hidden;
        }
    }

    /*
     * Radio Button
     */
    &__field--radio {
        margin: 0;
        line-height: $global-margin;

        input {
            cursor: pointer;
            position: absolute;
            left: 0;
            transform: translateX(#{rem(-4)}) translateY(#{rem(2)});
        }

        label {
            cursor: pointer;
            padding-left: rem(20);
        }
    }

    &__field--checkbox {
        input:checked {
            accent-color: cssvar(color-primary);
        }

        label {
            cursor: pointer;
        }
    }

    &__field-group {
        border: rem(1) solid cssvar(color-lightgray);
        padding: rem(5);
        margin: rem(3);
        transition: background .3s ease-in-out;
        &:hover,
        &:focus-visible {
            background-color: cssvar(color-mediumlightgray);
        }
    }

    /* Elearning Quizzes */

    &--quiz {

        .c-form__field--radio {
            margin-bottom: rem(4);

            label {
                padding: rem(12 20 12 36);
                border-radius: $global-radius;
                border: rem(1) solid cssvar(color-mediumgray);
                background-color: transparent;
                transition: background .3s ease-in-out;

                &:hover {
                    background-color: cssvar(color-mediumlightgray);
                }
            }

            input {
                left: rem(12);
            }
        }
    }

    &--waitinglist {
        display: inline-block;
    }

}
