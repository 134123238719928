/**
* Booking state component
* Seen on the course detail page and course listing item. 
* Shows whether or not a course is:
* - open:: lots of open slots 
* - limited:: not as many slots available 
* - soldout:: no slots available 
*/

        // open : phs #B1AB11
        // soldout: #BE2E00
        // limited; #F2C400

.c-bookingstate {
    position: relative;
    display: inline-block;
    
    &__content {
        display: flex;
        
        div {
            flex: 0 0 auto;
            border-radius: 50%;
            width: rem(25);
            height: rem(25);
            background-color: cssvar(color-mediumgray);
            margin: rem(3);
        }
    }
    
    &__open {
        &.is-active {
            background-color: #B1AB11;
        }
    }

    &__limited {
        &.is-active {
            background-color: #F2C400;
        }
    }

    &__soldout {
        &.is-active {
            background-color: #BE2E00;
        }
    }
    
}