/* ==========================================================================
   Dialog
   - Implementation of this dialog tool: https: //a11y-dialog.netlify.app/
   ========================================================================== */
   
.c-dialog {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;

    &[aria-hidden="true"] {
        display: none;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        animation: fade-in-overlay 400ms both;
    }

    &__content {
        z-index: 2;
        position: relative;
        animation: fade-in-slide-up 400ms 200ms both;
        margin: auto;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 90vh;
        background-color: cssvar(color-white);
        max-width: map-get($breakpoints, large);
        padding: rem(30);

        // hide scrollbar
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }
    }
    
    &__toolbar {
        margin-bottom: $global-margin;

        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }
       
    &__close {
        position: absolute;
        z-index: 4;
        top: 0;
        right: rem(8);
        border: 0;
        padding: rem(12);
        background-color: transparent;
        text-align: center;
        cursor: pointer;
        transition: 0.15s;

        svg {
            width: rem(16);
            height: rem(16);
        }

        &:hover {
            svg {
                fill: cssvar(color-secondary);
            }
        }
    }
}