/*
* Dashboard booking component found in the dashboard sections:
* Waiting List, Reservations 
*/

.c-dashboard-bookings {
    
    &__item {
        border-top: rem(1) solid rgb(var(--themecolor));
        
        &:last-child {
            border-bottom: rem(1) solid rgb(var(--themecolor));
        }
    }
    
    &__content {
        padding: rem(10 0);
    }
    
    &__date {
        text-align: center;
        @include breakpoint(medium) {
            text-align: right;
        }
        @include breakpoint(large) {
            text-align: center;
        }
    }
}