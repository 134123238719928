/*
* Partners listing component
* Found on the course detail page.
*/

.c-partners {
    
    li {
        margin-bottom: $global-margin*2;
    }
    
    figure {
        display: flex;
        align-items: center;
        
        figcaption, picture {
            display: block;
            flex: 1 1 50%;
        }
        
        img {
            width: 100%;
            max-width: rem(220);
            height: auto;
        }
        
        figcaption {
            color: cssvar(color-mediumgray);
            font-size: rem(13);
            padding: rem(0 16);
            margin-left: $global-margin;
            align-self: flex-end;
        }
    }
}