// This equals 100% content-width but in absolut units (rem)
$oneHundredPercent: calc(min($global-width, 100vw) - cssvar(content-gutter-left) - cssvar(content-gutter-right));

@mixin cellSize($grid-column-count, $idx) {
    // css-variable used in other nested objects, please keep it
    $percentage: #{$grid-column-count * $idx};
    --grid-cell-width: #{calc(($oneHundredPercent / $grid-column-count * $idx) - cssvar(grid-gutter))};
    width: calc((100% / $grid-column-count * $idx) - cssvar(grid-gutter));
}

// Mixin used to generate cell-classes per breakpoint
@mixin breakpointCell($breakpoint) {
    @for $idx from 1 through $grid-column-count {
        &--#{$breakpoint}-#{$idx} {
            @include cellSize($grid-column-count, $idx);
        }
    }

    &--#{$breakpoint}-auto {
        flex: 1 1 0;
        width: auto;
        --grid-cell-width: $oneHundredPercent;
    }

    &--#{$breakpoint}-shrink {
        flex: 0 0 auto;
        width: auto;
        --grid-cell-width: $oneHundredPercent;
    }
}

.l-grid {
    display: flex;
    flex-flow: row wrap;

    &--list {
        list-style-type: none;
        padding-inline-start: 0;
    }

    &--collapsed {
        // used when the grid should not use the outer negative margins
        margin-left: 0;
        margin-right: 0;
    }

    &--no-margin {
        @include setcssvar(grid-gutter, 0px);
    }

    &--align-right {
        justify-content: flex-end;
    }

    &--align-center {
        justify-content: center;
    }

    &--align-justify {
        justify-content: space-between;
    }

    &--justify-space-between {
        justify-content: space-between;
    }

    &--align-spaced {
        justify-content: space-around;
    }

    &--vertical-centered {
        align-items: center;
    }

    &--vertical-end {
        align-items: end;
    }

    .l-grid__cell {
        flex: 0 0 auto;
        order: var(--order, 0);
        min-height: 0;
        min-width: 0;
        width: calc(100% - cssvar(grid-gutter));
        margin-left: calc(cssvar(grid-gutter) / 2);
        margin-right: calc(cssvar(grid-gutter) / 2);

        &--auto {
            flex: 1 1 0;
            width: auto;
        }

        &--shrink {
            flex: 0 0 auto;
            width: auto;
        }

        &--no-margin {
            @include setcssvar(grid-gutter, 0px);
        }

        &--align-self-centered {
            align-self: center;
        }

        &--align-self-end {
            align-self: end;
        }

        @each $breakPointName,
        $breakpointValue in $breakpoints {
            @include breakpoint($breakPointName) {
                order: var(--#{$breakPointName}-order, var(--order));
                @include breakpointCell($breakPointName);
            }
        }
    }
}